//@ts-ignore
import { OrganizationStore } from "@vegga/front-store";

export const canActivateLink = (feature?: string, flags?: any, permissions?: string) => {
  const organizationStore = OrganizationStore.getInstance();

  const hasPermission = permissions ? organizationStore.hasPermission(permissions) : false;

  const hasFeature = feature ? organizationStore.getFeature(feature) : false;

  return hasPermission && hasFeature && flags;
};
