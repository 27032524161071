// import './organizations.component.scss';
import { NavLink } from "react-router-dom";
import { canActivateLink } from "../../utils";
import { IMenuItemProps } from "../../interfaces/menuOptions";
import i18next from "i18next";
import { useMemo } from "react";

export const MenuItem = ({
  external = false,
  feature,
  flags,
  icon,
  iconKind,
  label,
  language,
  moduleName,
  permissions,
  toggleMenuInMobile,
  token,
  url,
  isSidebarFolded,
  hoveredItem,
  setHoveredItem,
}: IMenuItemProps) => {
  if (
    !canActivateLink(feature, flags, permissions) ||
    moduleName === "irrigation-manager"
  )
    return null;
  const isHovered = useMemo(() => hoveredItem === label, [hoveredItem]);

  return external ? (
    <a
      onMouseEnter={() => setHoveredItem(label)}
      onMouseLeave={() => setHoveredItem("")}
      onClick={toggleMenuInMobile}
      href={url(token, language)}
      className="vegga-sidebar__menu-item"
      target="_blank"
    >
      <span className="vegga-sidebar__menu-icon">
        <vegga-icon icon={icon}></vegga-icon>
      </span>
      {(!isSidebarFolded || isHovered) && (
        <span
          className={
            isSidebarFolded && isHovered
              ? "vegga-sidebar__menu-label vegga-sidebar__menu-label--folded"
              : "vegga-sidebar__menu-label"
          }
        >
          {i18next.t(label)}
        </span>
      )}
    </a>
  ) : (
    <NavLink
      onMouseEnter={() => setHoveredItem(label)}
      onMouseLeave={() => setHoveredItem("")}
      onClick={toggleMenuInMobile}
      to={url(language, token)}
      className={({ isActive }) =>
        isActive
          ? `vegga-sidebar__menu-item vegga-sidebar__menu-item--active ${
              isSidebarFolded ? "vegga-sidebar__menu-item--active--folded" : ""
            }`
          : "vegga-sidebar__menu-item"
      }
      end
    >
      <span className="vegga-sidebar__menu-icon">
        <vegga-icon kind={iconKind} icon={icon}></vegga-icon>
      </span>
      {(!isSidebarFolded || isHovered) && (
        <span
          className={
            isSidebarFolded && isHovered
              ? "vegga-sidebar__menu-label vegga-sidebar__menu-label--folded"
              : "vegga-sidebar__menu-label"
          }
        >
          {i18next.t(label)}
        </span>
      )}
    </NavLink>
  );
};
