import "./organizations.component.scss";
import i18next from "../../i18n/i18n.config";

const Organizations = () => {
  return (
    <div className="vegga-sidebar__dropdown">
      <div className="vegga-sidebar__dropdown-content">
        <span className="vegga-sidebar__dropdown-label">
          {i18next.t("dropdown")}
        </span>
        <span className="vegga-sidebar__dropdown-customer">
          Agro Alt Penedes
        </span>
      </div>
      <div className="vegga-sidebar__dropdown-icon">
        <vegga-icon icon="arrow-down"></vegga-icon>
      </div>
    </div>
  );
};

export default Organizations;
